/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link, useNavigate, useParams } from "react-router-dom";

import useStore from "../../store";
import { AiOutlineSetting, AiFillCaretDown } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import * as storage from "../../services/storage";
// import Timer from "../hoc/sessionValidator";
import * as keys from "../../utils/constant";

export const NavBar = () => {
  const queryPage = useParams("id");
  console.log(queryPage)
  const setAuth = useStore.userStore((state) => state.setAuth);
  const auth = useStore.userStore((state) => state.auth);
  // const { activePage, setActivePage } = useStore.activePageStore(
  //   (state) => state
  // );
  // useEffect(() => {
  //   const pageName = storage.getLocalStorage("pageName");
  //   setActivePage(pageName ? pageName : "Patient List");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const profile = auth?.profile?.user;
  const navigate = useNavigate();
  const [patientTabActive, setPatientTabActive] = useState(true);
  const [billTabActive, setBillTabActive] = useState(false);
  const [paymentTabActive, setPaymentTabActive] = useState(false);
  //eslint-disable-next-line
  const [deskTabActive, setDeskTabActive] = useState(false);

  const handleLogout = () => {
    storage.deleteLocalStorage(keys.COMMON_SERVICE_AUTH_TOKEN);
    storage.deleteLocalStorage(keys.STORAGE_TOKEN_KEY);
    storage.deleteLocalStorage("pageName");
    storage.deleteLocalStorage(keys.STORAGE_PROFILE_KEY);
    setAuth({
      isAuthenticated: false,
      token: null,
      profile: null,
      commonServiceToken: null,
    });
    navigate("/");
  };
  // const setPage = (page) => {
  //   setActivePage(page);
  //   storage.setLocalStorage("pageName", page);
  // };
  const handlePatientActive = () => {
    setPatientTabActive(true);
    setBillTabActive(false);
    setPaymentTabActive(false);
    setDeskTabActive(false);
  };
  const handleBillActive = () => {
    setPatientTabActive(false);
    setBillTabActive(true);
    setPaymentTabActive(false);
    setDeskTabActive(false);
  };
  const handlePaymentActive = () => {
    setPatientTabActive(false);
    setBillTabActive(false);
    setPaymentTabActive(true);
    setDeskTabActive(false);
  };
  //eslint-disable-next-line
  const handleDeskActive = () => {
    setPatientTabActive(false);
    setBillTabActive(false);
    setPaymentTabActive(false);
    setDeskTabActive(true);
  };

  return (
    <>
      {/* <Timer /> */}
      <nav className="tw-sticky tw-top-0 tw-z-10 tw-bg-white tw-ml-3 tw-mr-3 tw-rounded tw-shadow-md">
        <div className=" tw-mx-auto tw-px-2 sm:tw-px-4 lg:tw-px-4">
          <div className="tw-relative tw-flex tw-items-center tw-justify-between tw-h-12">
            <div className="tw-flex-1 tw-flex tw-items-center tw-justify-center sm:tw-items-stretch sm:tw-justify-start">
              <div className="tw-flex-shrink-0 tw-flex tw-items-center">
                <Link to="">
                  <img
                    src={require("../../assets/images/brand/logo.png")}
                    alt="logo"
                    width={150}
                  />
                </Link>
              </div>
              <div className="tw-w-0.5 tw-mx-2 tw-ml-6 tw-bg-slate-200 md:tw-h-10"></div>
              <div className="tw-hidden sm:tw-block">
                <div className="tw-flex tw-space-x-4">
                  <Menu as="div" className="tw-ml-3 tw-relative">
                    <div>
                      <Menu.Button
                        className={
                          "tw-text-primary-color hover:tw-bg-primary-color hover:tw-text-white tw-flex tw-rounded-md hover:tw-text-sm focus:tw-outline-none hover:tw-rounded-md tw-transition-all tw-ease-in-oute-out tw-duration-200" +
                          (patientTabActive
                            ? " tw-border-b-2 tw-border-b-primary-color tw-rounded-b-none"
                            : "")
                        }
                      >
                        <div className="tw-inline-flex tw-gap-0.5 tw-justify-items-center tw-items-center tw-center tw-px-3 tw-py-2 tw-text-sm tw-font-medium ">
                          <span>Patient</span>
                          <AiFillCaretDown
                            size={10}
                            className="tw-mt-1"
                          ></AiFillCaretDown>
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="tw-transition tw-ease-out tw-duration-100"
                      enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                      enterTo="tw-transform tw-opacity-100 tw-scale-100"
                      leave="tw-transition tw-ease-in tw-duration-75"
                      leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                      leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                    >
                      <Menu.Items className="tw-origin-top-left tw-absolute tw-left-0 tw-mt-2 tw-w-32 tw-rounded-md tw-shadow-lg tw-py-1 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                        <Menu.Item>
                          <Link
                            className={
                              "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                            }
                            to="/Patient Report/report/page/1"
                            onMouseDown={(e) => {
                              // setPage("Patient List");
                              handlePatientActive();
                            }}
                          >
                            Patient Report
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            className={
                              "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                            }
                            to="/Visit Report/vReport/page/1"
                            onMouseDown={(e) => {
                              // setPage("Visit Report");
                              handlePatientActive();
                            }}
                          >
                            Visit Report
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            className={
                              "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                            }
                            to="/Batch Report/batch/page/1"
                            onMouseDown={(e) => {
                              // setPage("Patient List");
                              handlePatientActive();
                            }}
                          >
                            Batch Report
                          </Link>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  <Menu as="div" className="tw-ml-3 tw-relative">
                    <div>
                      <Menu.Button
                        className={
                          "tw-text-primary-color hover:tw-bg-primary-color hover:tw-text-white tw-flex tw-rounded-md hover:tw-text-sm focus:tw-outline-none hover:tw-rounded-md tw-transition-all tw-ease-in-oute-out tw-duration-200" +
                          (billTabActive
                            ? " tw-border-b-2 tw-border-b-primary-color tw-rounded-b-none"
                            : "")
                        }
                      >
                        <div className="tw-inline-flex tw-gap-0.5 tw-justify-items-center tw-items-center tw-center tw-px-3 tw-py-2 tw-text-sm tw-font-medium ">
                          <span>Bill</span>
                          <AiFillCaretDown
                            size={10}
                            className="tw-mt-1"
                          ></AiFillCaretDown>
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="tw-transition tw-ease-out tw-duration-100"
                      enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                      enterTo="tw-transform tw-opacity-100 tw-scale-100"
                      leave="tw-transition tw-ease-in tw-duration-75"
                      leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                      leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                    >
                      <Menu.Items className="tw-origin-top-left tw-absolute tw-left-0 tw-mt-2 tw-w-44 tw-rounded-md tw-shadow-lg tw-py-1 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                        <Menu.Item>
                          <Link
                            className={
                              "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                            }
                            to="/Bill Report/bReport/page/1"
                            onMouseDown={(e) => {
                              // setPage("Bill Report");
                              handleBillActive();
                            }}
                          >
                            Bill Report
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            className={
                              "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                            }
                            to="/Bill Submission Report/bsReport/page/1"
                            onMouseDown={(e) => {
                              // setPage("Bill Submission Report");
                              handleBillActive();
                            }}
                          >
                            Bill Submission Report
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            className={
                              "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                            }
                            to="/Bill Report By Provider/brpReport/page/1"
                            onMouseDown={(e) => {
                              // setPage("Bill Report By Provider");
                              handleBillActive();
                            }}
                          >
                            Bill Report By Provider
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            className={
                              "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                            }
                            to="/Visit Financial Report/vfReport/page/1"
                            onMouseDown={(e) => {
                              // setPage("Visit Financial Report");
                              handleBillActive();
                            }}
                          >
                            Visit Financial Report
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            className={
                              "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                            }
                            to="/Verification Report/verificationReport/page/1"
                            onMouseDown={(e) => {
                              // setPage("Bill Report By Provider");
                              handleBillActive();
                            }}
                          >
                            Verifications
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            className={
                              "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                            }
                            to="/Denial report/bdReport/page/1"
                            onMouseDown={(e) => {
                              // setPage("Bill Submission Report");
                              handleBillActive();
                            }}
                          >
                            Denials
                          </Link>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  <Menu as="div" className="tw-ml-3 tw-relative">
                    <div>
                      <Menu.Button
                        className={
                          "tw-text-primary-color hover:tw-bg-primary-color hover:tw-text-white tw-flex tw-rounded-md hover:tw-text-sm focus:tw-outline-none hover:tw-rounded-md tw-transition-all tw-ease-in-oute-out tw-duration-200" +
                          (paymentTabActive
                            ? " tw-border-b-2 tw-border-b-primary-color tw-rounded-b-none"
                            : "")
                        }
                      >
                        <div className="tw-inline-flex tw-gap-0.5 tw-justify-items-center tw-items-center tw-center tw-px-3 tw-py-2 tw-text-sm tw-font-medium ">
                          <span>Payment</span>
                          <AiFillCaretDown
                            size={10}
                            className="tw-mt-1"
                          ></AiFillCaretDown>
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="tw-transition tw-ease-out tw-duration-100"
                      enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                      enterTo="tw-transform tw-opacity-100 tw-scale-100"
                      leave="tw-transition tw-ease-in tw-duration-75"
                      leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                      leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                    >
                      <Menu.Items className="tw-origin-top-left tw-absolute tw-left-0 tw-mt-2 tw-w-36 tw-rounded-md tw-shadow-lg tw-py-1 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                        <Menu.Item>
                          <Link
                            className={
                              "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                            }
                            to="/Ar Report/arReport/page/1"
                            onMouseDown={(e) => {
                              // setPage("AR Report");
                              handlePaymentActive();
                            }}
                          >
                            AR Report
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            className={
                              "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                            }
                            to="/Payment Report/prReport/page/1"
                            onMouseDown={(e) => {
                              // setPage("Payment Report");
                              handlePaymentActive();
                            }}
                          >
                            Payment Report
                          </Link>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  <Menu as="div" className="tw-ml-3 tw-relative">
                    <div>
                      <Menu.Button
                        className={
                          "tw-text-primary-color hover:tw-bg-primary-color hover:tw-text-white tw-flex tw-rounded-md hover:tw-text-sm focus:tw-outline-none hover:tw-rounded-md tw-transition-all tw-ease-in-oute-out tw-duration-200 tw-z-20 " +
                          (deskTabActive
                            ? " tw-border-b-2 tw-border-b-primary-color tw-rounded-b-none"
                            : "")
                        }
                      >
                        <div className="tw-inline-flex tw-gap-0.5 tw-justify-items-center tw-items-center tw-center tw-px-3 tw-py-2 tw-text-sm tw-font-medium ">
                          <span>Arb/Lit Desk</span>
                          <AiFillCaretDown
                            size={10}
                            className="tw-mt-1"
                          ></AiFillCaretDown>
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="tw-transition tw-ease-out tw-duration-100"
                      enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                      enterTo="tw-transform tw-opacity-100 tw-scale-100"
                      leave="tw-transition tw-ease-in tw-duration-75"
                      leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                      leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                    >
                      <Menu.Items className="tw-origin-top-left tw-absolute tw-left-0 tw-mt-2 tw-w-44 tw-rounded-md tw-shadow-lg tw-py-1 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                        <Menu.Item>
                          <Link
                            className={
                              "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                            }
                            to="/Litigation Assignment/LitigationAssignment/page/1"
                            onMouseDown={(e) => {
                              //setPage("Litigation Assignment");
                              handleDeskActive();
                            }}
                          >
                            Assign Cases
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            className={
                              "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                            }
                            onMouseDown={(e) => {
                              //setPage("Litigation Summary");
                              handleDeskActive();
                              navigate(
                                "/Litigation Summary/LitigationSummary/page/1/summary"
                              );
                              window.location.reload();
                            }}
                          >
                            Assignment Summary
                          </Link>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
            <div
              className="tw-absolute lg:tw-flex sm:tw-hidden tw-left-1/2"
              style={{
                textAlign: "center",
              }}
            >
              <h4
                className="cursFont"
                style={{
                  fontSize: 20,
                  whiteSpace: "nowrap",
                  color: "var(--primary-bg-color)",
                }}
              >
                {`Provider Portal - ${queryPage.pageName || "Dashboard"}`}
              </h4>
            </div>
            <div
              className="tw-relative tw-text-sm tw-text-right "
              style={{ top: 5 }}
            >
              <h5 className="mb-0 fs-14 fw-semibold tw-text-primary-color">
                {profile && profile?.name ? profile?.name : "User"}
              </h5>
              <p
                className="tw-relative tw-text-sm tw-text-primary-color"
                style={{ top: -9 }}
              >
                {auth?.profile?.name && auth?.profile?.name
                  ? auth?.profile?.name
                  : "User"}
              </p>
            </div>
            <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 sm:tw-static sm:tw-inset-auto sm:tw-pr-0">
              <Menu as="div" className="tw-ml-3 tw-relative">
                <div>
                  <Menu.Button className="tw-bg-gray-800 tw-flex tw-text-sm tw-rounded-full focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-primary-color focus:tw-ring-white">
                    <span className="tw-sr-only">Open user menu</span>
                    <img
                      className="tw-h-8 tw-w-8 tw-rounded-full"
                      src={`data:image/jpeg;base64,${profile?.profilePicture}`}
                      alt="text"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="tw-transition tw-ease-out tw-duration-100"
                  enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                  enterTo="tw-transform tw-opacity-100 tw-scale-100"
                  leave="tw-transition tw-ease-in tw-duration-75"
                  leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                  leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                >
                  <Menu.Items className="tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw-w-48 tw-rounded-md tw-shadow-lg tw-py-1 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                    <Menu.Item>
                      <Link
                        className={
                          "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                        }
                        to="/settings"
                      >
                        <AiOutlineSetting size={18}></AiOutlineSetting> User
                        profile
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        className={
                          "tw-flex tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-gap-2"
                        }
                        onClick={() => handleLogout()}
                        to=""
                      >
                        <FiAlertCircle size={18}></FiAlertCircle> Sign Out
                      </Link>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
