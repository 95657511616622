import React from "react";
import { lazy, Suspense, useCallback, useEffect } from "react";

import "./App.css";
import "./index.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoutes";
import "./services/interceptor";
import PublicRoute from "./routes/PublicRoutes";
import * as storage from "./services/storage";
import * as constant from "./utils/constant";
import useStore from "./store";
import Notification from "./components/shared/Notification";
import { Loading } from "./components/shared";
import { history } from "./store/history";
import Login from "./views/auth/login";
import { NavBar } from "./components/shared/NavBar";
import Footer from "./components/shared/footer/index";
import { Toaster } from "react-hot-toast";
import { RedirectedLogin } from "./views/RedirectedLogin";
// import VerificationReport from "./views/VerificationReport";
const PatientList = lazy(() => import("./views/PatientList"));

const EligiAndBenifits = lazy(() => import("./views/eligibilityAndBenefits"));
const PatientDetail = lazy(() => import("./views/PatientList/patientDetail"));
const ArReport = lazy(() => import("./views/ArReport"));
const BillSubmittsionReport = lazy(() =>
  import("./views/BillSubmissionReport")
);
const UNANSWEREDVERIFICATION = lazy(() =>
  import("./views/UNANSWEREDVERIFICATION")
);
const VisitFinancialReport = lazy(() => import("./views/VisitFinancialReport"));
const BatchReport = lazy(() => import("./views/BatchReport"))
const VisitReport = lazy(() => import("./views/VisitReport"));
const PaymentReport = lazy(() => import("./views/PaymentReport"));
const BillReportByProvider = lazy(() => import("./views/BillReportByProvider"));
const ViewBills = lazy(() => import("./views/ViewBills"));
const CritiCalVisitReport = lazy(() => import("./views/CriticalVisitReport"));
const POMNotReceviedReport = lazy(() => import("./views/POMNOTRecevied"));
const MissingInfoCardGridCommon = lazy(() =>
  import("./views/MissingInfoCommon")
);
const NoCorrespondenceDetails = lazy(() =>
  import("./views/NoCorrespondenceDetails")
);
const NoCorrespondenceDetailsAnAnswered = lazy(() =>
  import("./views/NoCorrespondenceDetailsAnAnswered")
);
const Hp1PendingList = lazy(() => import("./views/HP1PendingList"));
const BillDenialComponent = lazy(() => import("./views/billDenial"));
const PomNotGenratedComponent = lazy(() => import("./views/PomNotGenreted"));
const AdminDashboard = lazy(() => import("./views/Dashboard"));
const Litigation = lazy(() => import("./views/LitigationDesk"));
const VerificationReport = lazy(() => import("./views/VerificationReport"));
const DenialReport = lazy(() => import("./views/DenialReport"));

function App() {
  const setAuth = useStore.userStore((state) => state.setAuth);
  const pageLength = useStore.tablePageLength((state) => state.pageLength);
  const setPageLength = useStore.tablePageLength(
    (state) => state.setPageLength
  );
  useEffect(() => {
    if (
      localStorage.getItem("tablePageLength") === null ||
      localStorage.getItem("tablePageLength") === undefined ||
      localStorage.getItem("tablePageLength") === 0
    ) {
      setPageLength(25);
    } else {
      setPageLength(localStorage.getItem("tablePageLength"));
    }
  }, [setPageLength]);

  useEffect(() => {
    if (pageLength === 0 || pageLength === null || pageLength === undefined) {
      localStorage.setItem("tablePageLength", 25);
    } else {
      localStorage.setItem("tablePageLength", pageLength);
    }
  }, [pageLength]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkUser = useCallback(() => {
    const checkUserIsLoginOrNot = () => {
      const token = storage.getLocalStorage(constant.STORAGE_TOKEN_KEY);
      const commonServiceToken = storage.getLocalStorage(
        constant.COMMON_SERVICE_AUTH_TOKEN
      );
      if (token) {
        const profile = storage.getLocalStorage(constant.STORAGE_PROFILE_KEY);
        setAuth({
          isAuthenticated: true,
          profile: profile,
          token: token,
          commonServiceToken: commonServiceToken,
        });
      }
    };
    checkUserIsLoginOrNot();
  });

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  return (
    <>
      <div className="App reportsSection">
        <Loading />
        <Toaster
          position="top-right"
          toastOptions={{ style: { borderRadius: "0.125rem" }, duration: 3000 }}
        />
        <Router basename="/" history={history}>
          <Notification></Notification>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route
                exact
                path="/login"
                element={
                  <PublicRoute>
                    <Login />
                  </PublicRoute>
                }
              />
              <Route
                exact
                path="/redirectedLogin"
                element={
                  <PublicRoute>
                    <RedirectedLogin />
                  </PublicRoute>
                }
              />
              <Route
                element={
                  <>
                    <NavBar />
                    <Outlet />
                    <Footer />
                  </>
                }
              >
                <Route
                  path="/:pageName/report/page/:id"
                  element={
                    <ProtectedRoute>
                      <PatientList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <AdminDashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/bdreport/page/:id"
                  element={
                    <ProtectedRoute>
                      <DenialReport />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/patientdetail/:id"
                  element={
                    <ProtectedRoute>
                      <PatientDetail />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/arReport/page/:id"
                  element={
                    <ProtectedRoute>
                      <ArReport />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/batch/page/:id"
                  element={
                    <ProtectedRoute>
                      <BatchReport />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/vfReport/page/:id"
                  element={
                    <ProtectedRoute>
                      <VisitFinancialReport />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/vReport/page/:id"
                  element={
                    <ProtectedRoute>
                      <VisitReport />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/BenifitCheckUpApplication/:cmpId/:patientId"
                  element={
                    <ProtectedRoute>
                      <EligiAndBenifits />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/crReport/page/:id"
                  element={
                    <ProtectedRoute>
                      <CritiCalVisitReport />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/Hp1PendingList/page/:id"
                  element={
                    <ProtectedRoute>
                      <Hp1PendingList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/bsReport/page/:id"
                  element={
                    <ProtectedRoute>
                      <BillSubmittsionReport />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/billDenial/page/:id"
                  element={
                    <ProtectedRoute>
                      <BillDenialComponent />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/noCorrespondenceDetails/page/:id"
                  element={
                    <ProtectedRoute>
                      <NoCorrespondenceDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/NoCorrespondenceDetailsAnAnswered/page/:id"
                  element={
                    <ProtectedRoute>
                      <NoCorrespondenceDetailsAnAnswered />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/pomNotGenrated/page/:id"
                  element={
                    <ProtectedRoute>
                      <PomNotGenratedComponent />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/pomNotRecevied/page/:id"
                  element={
                    <ProtectedRoute>
                      <POMNotReceviedReport />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/unAnsweredVerification/page/:id"
                  element={
                    <ProtectedRoute>
                      <UNANSWEREDVERIFICATION />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/Info/:report/page/:id"
                  element={
                    <ProtectedRoute>
                      <MissingInfoCardGridCommon />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/vReport/page/:id/:caseNo/:providerName"
                  element={
                    <ProtectedRoute>
                      <VisitReport />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/prReport/page/:id"
                  element={
                    <ProtectedRoute>
                      <PaymentReport />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/brpReport/page/:id"
                  element={
                    <ProtectedRoute>
                      <BillReportByProvider />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/bReport/page/:id"
                  element={
                    <ProtectedRoute>
                      <ViewBills />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/bReport/:providerName/:providerLocation/page/:id"
                  element={
                    <ProtectedRoute>
                      <ViewBills />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/LitigationAssignment/page/:id"
                  element={
                    <ProtectedRoute>
                      <Litigation />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/LitigationAssignment/page/:id/:lawfirm"
                  element={
                    <ProtectedRoute>
                      <Litigation />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/LitigationSummary/page/:id/:action"
                  element={
                    <ProtectedRoute>
                      <Litigation />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:pageName/verificationReport/page/:id"
                  element={
                    <ProtectedRoute>
                      <VerificationReport />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="/" element={<Navigate to={"/login"} />} />
              <Route
                path="*"
                element={<Navigate to={"/Patient Report/report/page/1"} />}
              />
            </Routes>
          </Suspense>
        </Router>
      </div>
    </>
  );
}

export default App;
