import { LIGHT_MODE, DARK_MODE, SIDEBAR_LAYOUT } from "./constant";
import moment from "moment/moment";
import { PDFDocument } from "pdf-lib";
import { saveAs } from "file-saver";
import axios from "axios";
import jsPDF from "jspdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const changeClass = (classes) => {
  document.body.classList.add(classes);
};

export const bodyClassCheck = (classname) => {
  const bodyHasClass = document.body.classList.contains(classname);
  if (bodyHasClass) {
    return true;
  } else {
    return false;
  }
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const changeLayout = (className) => {
  if (bodyClassCheck(LIGHT_MODE)) {
    document.body.classList.remove(LIGHT_MODE);
    changeClass(DARK_MODE);
  } else {
    document.body.classList.remove(DARK_MODE);
    changeClass(LIGHT_MODE);
  }
};

export const downloadBuffer = (arrayBuffer, fileName) => {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(
    new Blob([arrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  a.download = `${fileName}_${moment().format("MMDDyyyy")}`;
  a.click();
};

export const changeSidebarLayout = (className) => {
  if (bodyClassCheck(SIDEBAR_LAYOUT)) {
    document.body.classList.remove(SIDEBAR_LAYOUT);
  } else {
    changeClass(SIDEBAR_LAYOUT);
  }
};

export const ValidateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const removeClass = (classes) => {
  document.body.classList.remove(classes);
};

export const convertDateTO = (dat) => {
  const date = new Date(dat);
  var d = date.getDate();
  var m = date.getMonth() + 1;
  var y = date.getFullYear();
  return `${m <= 9 ? "0" + m : m}-${d <= 9 ? "0" + d : d}-${y}`;
};

export const buildUrl = (url, parameters) => {
  var qs = "";
  for (var key in parameters) {
    var value = parameters[key];
    if (value && value !== "All") {
      qs += encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + "?" + qs;
  }
  return url;
};

export const filtersDatesTypes = {
  lastWeek: {
    startDate: moment().subtract(1, "weeks").startOf("week"),
    endDate: moment().subtract(1, "weeks").endOf("week"),
  },
  lastMonth: {
    startDate: moment().subtract(1, "month").startOf("month"),
    endDate: moment().subtract(1, "month").endOf("month"),
  },
  lastQuarter: {
    startDate: moment().subtract(1, "quarter").startOf("quarter"),
    endDate: moment().subtract(1, "quarter").endOf("quarter"),
  },
  lastYear: {
    startDate: moment().subtract(1, "year").startOf("year"),
    endDate: moment().subtract(1, "year").endOf("year"),
  },
  thisWeek: {
    startDate: moment().startOf("week").toDate(),
    endDate: moment().endOf("week").toDate(),
  },
  thisMonth: {
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
  },
  last3Months: {
    startDate: moment().subtract(2, "month").startOf("month"),
    endDate: moment(new Date()),
  },
  thisYear: {
    startDate: moment().startOf("year"),
    endDate: moment().endOf("year"),
  },
};

export const formattedDate = (dateObj, show) => {
  if (show) {
    return dateObj ? moment(dateObj).format("MM/DD/YY hh:mm A") : "";
  } else {
    return dateObj ? moment(dateObj).format("MM/DD/YY hh:mm") : "";
  }
};

export const PrintElem = (element) => {
  var divContents = document.getElementById(element).innerHTML;
  var a = window.open();
  a.document.write("<html>");
  a.document.write(divContents);
  a.document.write("</body></html>");
  a.document.close();
  a.print();
};

export const getPdfArrayBuffer = (temp) =>
  new Promise((resolve) => {
    temp.getBlob((blob) => {
      return resolve(blob.arrayBuffer());
    });
  });

export const GenerateMergedPdf = async (PdfArray, FileName, action) => {
  if (PdfArray.length > 0) {
    const mergedPdf = await PDFDocument.create();
    for (let i = 0; i < PdfArray.length; i++) {
      let tempPdf = await PDFDocument.load(PdfArray[i], {
        ignoreEncryption: true,
      });
      const copiedPages = await mergedPdf.copyPages(
        tempPdf,
        tempPdf.getPageIndices()
      );
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }
    const mergedPdfFile = await mergedPdf.save();
    let file = new Blob([mergedPdfFile], {
      type: "application/pdf",
    });

    if (action === "download") {
      saveAs(
        file,
        `${FileName.replaceAll(" ", "")}_${new Date().toLocaleDateString(
          "en-US"
        )}.pdf`
      );
    } else if (action === "print") {
      window.open(URL.createObjectURL(file));
    } else {
      return mergedPdfFile;
    }
  }
};

export const getPdfData = async (fileUrls) => {
  if (fileUrls && fileUrls?.length !== 0) {
    const tempPromises = await Promise.all(
      fileUrls?.map((element, index) => {
        return axios.get(
          process.env.REACT_APP_API_URL +
            `Referral/GetReferralDocument/?FilePath=${element}`,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("_token")).token
              }`,
            },
            responseType: "blob",
          }
        );
      })
    );
    const tempData = await tempPromises?.map((data) => {
      return data.data;
    });
    const finalDataPromises = await tempData?.map((data) => data.arrayBuffer());
    const finalData = await Promise.all(finalDataPromises);
    return finalData;
  }
};

export const GenerateRefferalPdf = async (formData, userProfile) => {
  let tempPdfDefination = [];
  tempPdfDefination.push({
    style: "MainHeader",
    text: `${userProfile.user?.name}`,
  });
  tempPdfDefination.push({
    style: "SubHeader",
    text: `${userProfile.user?.address.replace("\r\n", "")}`,
  });
  tempPdfDefination.push({
    text: `\n`,
  });
  tempPdfDefination.push({
    canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
  });
  tempPdfDefination.push({
    text: `\n`,
  });
  tempPdfDefination.push({
    style: "MainTable",
    table: {
      widths: [80, "*"],
      body: [
        [
          {
            text: `Doctor's Name: `,
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: `${
              formData.provider === null ||
              formData.provider === undefined ||
              formData.provider.length === 0
                ? ""
                : formData.provider
            }`,
            border: [false, false, false, false],
            style: "CommonText",
          },
        ],
      ],
    },
  });
  tempPdfDefination.push({
    style: "MainTable",
    table: {
      widths: [80, "*"],
      body: [
        [
          {
            text: `Doctor's Address: `,
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: `${
              formData.providerAndAddress === null ||
              formData.providerAndAddress === undefined ||
              formData.providerAndAddress.length === 0
                ? ""
                : formData.providerAndAddress
            }`,
            border: [false, false, false, false],
            style: "CommonText",
          },
        ],
      ],
    },
  });
  tempPdfDefination.push({
    style: "MainTable",
    table: {
      widths: [80, "*", 80, "*"],
      body: [
        [
          {
            text: `Work Phone: `,
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: `${
              formData.workPhone === null ||
              formData.workPhone === undefined ||
              formData.workPhone.length === 0
                ? ""
                : formData.workPhone
            }`,
            border: [false, false, false, false],
            style: "CommonText",
          },
          {
            text: `Other Phone: `,
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: `${
              formData.otherPhone === null ||
              formData.otherPhone === undefined ||
              formData.otherPhone.length === 0
                ? ""
                : formData.otherPhone
            }`,
            border: [false, false, false, false],
            style: "CommonText",
          },
        ],
      ],
    },
  });
  tempPdfDefination.push({
    style: "MainTable",
    table: {
      widths: [80, "*"],
      body: [
        [
          {
            text: `Reference#: `,
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: `${
              formData.referenceNumber === null ||
              formData.referenceNumber === undefined ||
              formData.referenceNumber.length === 0
                ? ""
                : formData.referenceNumber
            }`,
            border: [false, false, false, false],
            style: "CommonText",
          },
        ],
      ],
    },
  });
  tempPdfDefination.push({
    text: `\n`,
  });
  tempPdfDefination.push({
    canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
  });
  tempPdfDefination.push({
    text: `\n`,
  });
  tempPdfDefination.push({
    style: "MainTable",
    table: {
      widths: [80, "*", 80, "*"],
      body: [
        [
          {
            text: `Patient: `,
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: `${formData.firstName} ${
              formData.midleName === null ||
              formData.midleName === undefined ||
              formData.midleName?.length === 0 ||
              formData?.midleName === " "
                ? ""
                : formData.midleName
            } ${formData.lastName}`,
            border: [false, false, false, false],
            style: "CommonText",
          },
          {
            text: `Date: `,
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: `${new Date().toLocaleDateString("en-US")}`,
            border: [false, false, false, false],
            style: "CommonText",
          },
        ],
      ],
    },
  });
  tempPdfDefination.push({
    style: "MainTable",
    table: {
      widths: [80, "*", 80, "*"],
      body: [
        [
          {
            text: `DOB: `,
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: `${
              new Date(formData.dob).toLocaleDateString("en-US") ===
              "Invalid Date"
                ? ""
                : new Date(formData.dob).toLocaleDateString("en-US")
            }`,
            border: [false, false, false, false],
            style: "CommonText",
          },
          {
            text: `First visit on: `,
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: `${
              new Date(formData.firstVisitOn).toLocaleDateString("en-US") ===
              "Invalid Date"
                ? ""
                : new Date(formData.firstVisitOn).toLocaleDateString("en-US")
            }`,
            border: [false, false, false, false],
            style: "CommonText",
          },
        ],
      ],
    },
  });
  tempPdfDefination.push({
    style: "MainTable",
    table: {
      widths: [80, "*", 80, "*"],
      body: [
        [
          {
            text: `Age: `,
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: `${
              isNaN(moment().diff(formData.dob, "years", false)) === true
                ? " "
                : moment().diff(formData.dob, "years", false)
            }`,
            border: [false, false, false, false],
            style: "CommonText",
          },
          {
            text: `Gender: `,
            border: [false, false, false, false],
            bold: true,
          },
          {
            text: `${formData.gender}`,
            border: [false, false, false, false],
            style: "CommonText",
          },
        ],
      ],
    },
  });
  tempPdfDefination.push({
    text: `\n`,
  });
  tempPdfDefination.push({
    canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
  });
  tempPdfDefination.push({
    text: `\n`,
  });
  tempPdfDefination.push({
    text: `Referral For`,
    style: "CommonTextBold",
  });
  tempPdfDefination.push({
    style: "SubTable",
    table: {
      widths: ["*"],
      heights: [30],
      body: [
        [
          `${
            formData.referralFor === null ||
            formData.referralFor === undefined ||
            formData.referralFor.length === 0
              ? ""
              : formData.referralFor
          }`,
        ],
      ],
    },
  });
  tempPdfDefination.push({ text: "\n" });
  tempPdfDefination.push({
    text: `Major Complaint`,
    style: "CommonTextBold",
  });
  tempPdfDefination.push({
    style: "SubTable",
    table: {
      widths: ["*"],
      heights: [30],
      body: [
        [
          `${
            formData.majorComplaint === null ||
            formData.majorComplaint === undefined ||
            formData.majorComplaint.length === 0
              ? ""
              : formData.majorComplaint
          }`,
        ],
      ],
    },
  });
  tempPdfDefination.push({ text: "\n" });
  tempPdfDefination.push({
    text: `Diagnosis`,
    border: [false, false, false, false],
    style: "CommonTextBold",
  });
  tempPdfDefination.push({
    style: "SubTable",
    table: {
      widths: ["*"],
      heights: [30],
      body: [
        [
          `${
            formData.dignosisCode?.length === 0 ||
            formData.dignosisCode === null ||
            formData.dignosisCode === undefined
              ? ""
              : formData.dignosisCode.join(",")
          }`,
        ],
      ],
    },
  });
  tempPdfDefination.push({ text: "\n" });
  tempPdfDefination.push({
    text: `Special Instructions`,
    border: [false, false, false, false],
    style: "CommonTextBold",
  });
  tempPdfDefination.push({
    style: "SubTable",
    table: {
      widths: ["*"],
      heights: [30],
      body: [
        [
          `${
            formData.specialInstructions === null ||
            formData.specialInstructions === undefined ||
            formData.specialInstructions.length === 0
              ? ""
              : formData.specialInstructions
          }`,
        ],
      ],
    },
  });
  tempPdfDefination.push({ text: "\n" });
  tempPdfDefination.push({
    text: `Referring Doctor's Comments`,
    border: [false, false, false, false],
    style: "CommonTextBold",
  });
  tempPdfDefination.push({
    style: "SubTable",
    table: {
      widths: ["*"],
      heights: [30],
      body: [
        [
          `${
            formData.referralDoctorComments === null ||
            formData.referralDoctorComments === undefined ||
            formData.referralDoctorComments.length === 0
              ? ""
              : formData.referralDoctorComments
          }`,
        ],
      ],
    },
  });
  let tableBody = formData.documentId?.map((element) => {
    let tempArray = [];
    tempArray.push({
      text: element.split("/").pop().trim(),
      style: "CommonText",
    });
    return tempArray;
  });
  tableBody?.splice(0, 0, [
    {
      text: `Last Uploaded Files`,
      style: "MainTable",
    },
  ]);
  tempPdfDefination.push({
    text: `\n`,
  });
  tempPdfDefination.push({
    canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
  });
  tempPdfDefination.push({
    text: `\n`,
  });
  tempPdfDefination.push({
    alignment: "right",
    table: {
      widths: ["*"],
      body: [
        [
          {
            text: `Doctor's Signature`,
            border: [false, false, false, false],
            fontSize: 10,
            bold: true,
          },
        ],
      ],
    },
  });
  tempPdfDefination.push({
    text: `\n`,
  });
  tempPdfDefination.push({
    alignment: "right",
    image: `data:image/jpeg;base64,${userProfile?.user?.signature}`,
    fit: [100, 100],
  });
  tempPdfDefination.push({
    text: `\n`,
  });
  tempPdfDefination.push({
    alignment: "right",
    table: {
      widths: ["*"],
      body: [
        [
          {
            text: `${userProfile.user?.name}`,
            border: [false, false, false, false],
            fontSize: 10,
            bold: true,
          },
        ],
      ],
    },
  });
  tempPdfDefination.push({
    alignment: "right",
    table: {
      widths: ["*"],
      body: [
        [
          {
            text: [
              { text: `Date: `, bold: true },
              {
                text: `${new Date().toLocaleDateString("en-US")}`,
                style: "CommonText",
              },
            ],
            border: [false, false, false, false],
            fontSize: 10,
          },
        ],
      ],
    },
  });
  const docDefinition = {
    content: [tempPdfDefination],
    pageSize: "A4",
    styles: {
      MainHeader: {
        fontSize: 12,
        bold: true,
        alignment: "center",
        color: "black",
      },
      SubHeader: {
        fontSize: 10,
        alignment: "center",
        color: "black",
      },
      CommonText: {
        fontSize: 10,
        alignment: "left",
        color: "black",
      },
      CommonTextBold: {
        fontSize: 10,
        alignment: "left",
        color: "black",
        bold: true,
      },
      MainTable: {
        fontSize: 10,
        alignment: "left",
      },
      SubTable: {
        fontSize: 10,
      },
      CommonTableHeader: {
        fontSize: 10,
        bold: true,
      },
      CommonTableData: {
        fontSize: 8,
      },
    },
  };
  const tempPdf = await pdfMake.createPdf(docDefinition);
  return tempPdf;
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /* eslint-disable no-useless-escape */
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isValidPhone = (p) => {
  if (p) {
    /* eslint-disable no-useless-escape */
    return /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
      p
    );
  } else {
    return false;
  }
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const returnArryTOStringValue = (array, String) => {
  if (String) {
    return array.join(String);
  } else {
    return array.toString();
  }
};

export const getUniqueListFromArrayByKey = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export function FileToBase64(blob) {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
  });
}

export const getWindowDimensions = () => {
  const { innerWidth: width } = window;
  return width;
};

export const commafy = (num) => {
  var str = num.toString().split(".");
  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  }
  if (str[1] && str[1].length >= 4) {
    str[1] = str[1].replace(/(\d{3})/g, "$1 ");
  }
  return str.join(".");
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const imgToPdfBuffer = (blob) => {
  let image;

  image = document.createElement("img");
  image.src = URL.createObjectURL(blob);
  image.onload = function () {
    URL.revokeObjectURL(image.src);
  };

  let doc = new jsPDF();
  const imgProps = doc.getImageProperties(image.src);
  const pdfWidth = doc.internal.pageSize.getWidth();
  const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  doc.addImage(
    image.src,
    image.imageType,
    0,
    pdfHeight / 2,
    pdfWidth,
    pdfHeight
  );
  return doc.output("arraybuffer");
};
